
.list-button{
    background:none;
    outline:none;
    border:none;
    font-size: 0.9em;
    font-weight: 500;
    cursor:pointer;
    transform:translateX(0);

    &,svg path{
        color:#30343a;
    }

    padding:$base-padding*2;
    transition: color 0.2s ease-out, 0.2s ease-out transform;


    &:hover{
        transform:translateX(0.3rem);
        &, svg path{
            color:lighten(#30343a,10%);
        }
    }

}

.arrow-button{
    background:none;
    outline:none;
    border:none;
    font-size: 0.9em;
    font-weight: 500;
    cursor:pointer;
    transform:translateX(0);

    &,svg path{
        color:$link-color;
    }

    padding:$base-padding;
    transition: color 0.2s ease-out, 0.2s ease-out transform;

    &:hover{
        &, svg path{
            color:darken($link-color,10%);
        }
    }

    &:first-child{
        &:hover{
            transform:translateX(-0.3rem);
            &, svg path{
                color:darken($link-color,10%);
            }
        }
        margin-right:$base-padding;
    }

    &:last-child{
        &:hover{
            transform:translateX(0.3rem);
            &, svg path{
                color:darken($link-color,10%);
            }
        }
        margin-left:$base-padding;
    }
}




.button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:$base-padding*1.5 $base-padding*2;
    background:$button-color;
    /*background-image: linear-gradient(to right, lighten($secondary-color-2,10%), $secondary-color-2);*/
    color:$button-text-color;
    border-radius:3px;
    text-decoration: none;
    cursor:pointer;
    font-size:1.6rem;
    font-weight:600;
    transition:0.2s ease-out background-color, transform 0.2s ease-out;
    border:none;
    text-align: center;
    min-width: 150px;
    box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.05), 0 3px 10px -3px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
    
    svg{
        margin-right:1rem;
    }

    .text{
        color:$button-text-color;
        margin-left:$base-padding;
    }

    svg path{
        color:$button-text-color;
    }

    &:focus{
        outline: none;
        background-color:darken($button-color,15%);
        transform:scale(0.98);
    }

    &:hover{
        background-color:darken($button-color,10%);
    }

    &:active{
        background-color:darken($button-color,15%);
        transform:scale(0.96);
    }

    &.hidden{
        pointer-events: none;
        visibility: hidden;
    }
}

.text-link{
    font-size:1.3rem;
    font-weight:600;
    color:$link-color;
    transition: 0.2s ease-out color;
    
    &:focus{
        outline: none;
        color:darken($link-color,10%);
    }

    &:hover{
        color:darken($link-color,10%);
    }

    &:active{
        color:darken($link-color,15%);
    }

}

.button-text{
    font-size:1.6rem;
    background:none;
    border:none;
    cursor:pointer;
    font-weight:600;
    color:$link-color;
    text-align: left;

    &,span,svg path{
        color:$link-color;
        fill:$link-color;
        transition: 0.2s ease-out color;
    }

    svg{
        &:last-child{
            margin-left:$base-padding;
        }
        &:first-child{
            margin-right:$base-padding;
        }
        
        
        path{
            color:$base-color;
        }
    }

    &:focus, &:focus span, &:focus svg path{
        outline: none;
        color:darken($link-color,10%);
    }

    &:hover, &:hover span, &:hover svg path{
        color:darken($link-color,10%);
    }

    &:active, &:active span, &:active svg path{
        color:darken($link-color,15%);
    }

    &.pdf-button{
        span{
            color: #D4746B;
        }
        svg path{
            fill: #D4746B;
        }

        &:focus, &:focus span, &:focus svg path{
            color:darken(#D4746B,10%);
        }
    
        &:hover, &:hover span, &:hover svg path{
            color:darken(#D4746B,10%);
        }
    
        &:active, &:active span, &:active svg path{
            color:darken(#D4746B,15%);
        }
    }
}

.no-bg{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:$base-padding;
    background:none;
    outline:none;
    border:none;
    border-radius:3px;
    text-decoration: none;
    cursor:pointer;
    font-size:1.6rem;
    font-weight:600;
    transition:0.2s ease-out color, 0.2s ease-out background-color;
    box-shadow: none;
    svg{
        &:last-child{
            margin-left:$base-padding;
        }
        &:first-child{
            margin-right:$base-padding;
        }
        
        
        path{
            color:$base-color;
        }
    }

    


    &:hover{
        color:rgba( $base-color, .7 );
    }

    &:focus{
        background-color:rgba(0,0,0,0.1);
    }
}

.hide-button{
    font-size:1.5rem;
    border-radius:3px;
    padding:1rem 0.5rem;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05), 0 3px 10px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);

    &.hide{
        background-color:#e14857;
        &:hover{
            background-color:darken(#e14857,5%);
        }
        &:active{
            background-color:darken(#e14857,10%);
        }
    }
    &.reveal{
        background-color:#41c15f;
        &:hover{
            background-color:darken(#41c15f,5%);
        }
        &:active{
            background-color:darken(#41c15f,10%);
        }
    }

    svg{
        filter:drop-shadow(0 3px 5px rgba(0, 0, 0, 0.05));
        path{
            fill:#fff;
            
        }
    } 
}

.sort-button{
    text-align: left;
    border:none;
    outline:none;
    cursor:pointer;
    font-weight:600;
    transition: .2s ease-out opacity;

    &.selected{
        color:#ff6a13;
        svg path{
            fill:#ff6a13;
        }
    }

    &.sort-inactive{
        cursor: auto;
        transition: none;
        &:hover{
            opacity:1;
        }
    }

    .arrow{
        margin-left:1rem;

        &.asc{

        }

        &.desc{
            svg{
                transform:rotate(-180deg);
            }
        }

    }


    &:hover{
        opacity:0.8;
    }
}