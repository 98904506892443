.container-404{
    min-height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    h4{
        color:rgba(#30343a,0.3);
        font-size:1.6rem;
        text-align: center;
        line-height: 2.5rem;
    }
}