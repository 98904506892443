.tooltip-container{
    position: relative;
    
    &:hover{
        .tooltip{
            opacity: 1;
            transform: scale(1);
            pointer-events: all;
            &.tooltip-regular{
                transform:scale(1) translate(0,-50%);
            }
        }
    }
}


.popper-wrapper{
    z-index:20;
    pointer-events: none;
}

.tooltip{
    margin:0 1rem;
    font-size: 1.4rem;
    box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.05), 0 3px 10px -3px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
    background: rgba(255,255,255,0.9);
    font-weight: 600;
    text-shadow: none;
    padding: 1rem;
    border-radius: 5px;
    pointer-events: none;
    opacity:0;
    transform: scale(0.8);
    transform-origin: right center;
    transition: 0.2s ease-out opacity,0.2s ease-out transform;

    &.tooltip-regular{
        position: absolute;
        top:50%;
        right:100%;
        transform:scale(0.8) translate(0,-50%);
    }

    &.tooltip-max-width{
        width: 200px;
    }

    .text{
        display: block;
        margin-bottom: $base-padding/2;
        text-transform: capitalize;
        font-weight: 600;
        white-space: nowrap;
        text-align: right;
        &:last-child{
            margin-bottom:0;
        }
    }
    
    &.tooltip-right{
        right:auto;
        left:100%;
        margin-left:$base-padding;
        transform-origin: left center;
        .text{
            text-align: left;
        }
    }
}