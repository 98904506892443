main{
    min-height: calc(100vh - 5.5em);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}



.wrapper,.wrapper-1280{
    max-width: 960px;
    width:100%;
    margin:auto;
    padding:0 $base-padding;

    &.flex-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.wrapper-1280{
    max-width: 1280px;
    padding:0 $base-padding*2;
}

.wrapper-1200{
    max-width: 1200px;
    padding:0 $base-padding*2;
}



.actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.ads-container{
    margin-top:$base-padding*2;
    padding:$base-padding 0 ;
}


.top-container{
    position: relative;
    z-index: 1;
    max-width: 960px;
    margin:auto;
    margin-bottom:$base-padding*2;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;


    .title-container{
        display: flex;
        flex-direction: column;
        text-shadow: 0px 1px 10px rgba(0,0,0,0.4);


        .info-container{
            display:flex;
            align-items: center;

            .date{
                svg{
                    font-size: 2rem;
                    margin-left:$base-padding;
                    filter: drop-shadow(0px 1px 10px rgba(0,0,0,0.4));
                }
                svg path{
                    fill:#FFF;
                }
            }

        }

        .title-info{
            display: flex;
            flex-direction: column;
            font-size: 1.3rem;
            font-weight: 500;
            span{
                margin-right:$base-padding;
                color: #FFF;
            }
        }
        margin-bottom:1em;    
    }

    h1,h3{
        font-size:1.5em;
    }
    .button{
        font-size:0.9em;
    }


    @media(min-width:$mobile-max-width){
        align-items: center;
        flex-direction: row;
        .title-container{
            margin-bottom:0;
        }
    }

}

.ad-navigation{
    @media(max-width:$mobile-max-width){
        display: flex;
        justify-content: space-between;
        width:100%;
    }

    button:first-child{
        margin-right:$base-padding*2;
    }
}

body{
    background:#f9f8f7;
}

article{
    min-height:calc(100vh - 280px);
}

.text-wrapper{
    max-width:720px;
    margin:auto;
    margin-top:6rem;
    margin-bottom:4rem;
    padding:$base-padding;

    h1{
        margin-bottom:$base-padding*3;
        text-align: left;
    }

    p{
        line-height:1.5em;
        margin-bottom:$base-padding*2;
    }

    a{
        display: inline-block;
    }

    .goback-button{
        margin-top: 4rem;
        display: inline-block;
    }
}