.breadcrumbs-wrapper{
    width:100%;
    top:0;
    left:0;
    z-index: 1;
    background:#f9f8f7;
    /*box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.05), 0 3px 10px -3px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);*/

    /*&::before{
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
        background-position: top;
        background-size: cover;
        -webkit-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
        -webkit-filter: blur(20px);
        filter: blur(30px);
    }*/
}

.breadCrumbs{
    position: relative;
    margin: auto;
    display: block;
    max-width: 1280px;
    padding:$base-padding $base-padding*2;
    line-height: 3rem;
    display: flex;
    flex-wrap:wrap;

    span,a{
        color:$link-color;
        font-weight: 600;
        transition: .2s ease-out opacity;
        text-decoration: none;
    }

    >span{
        margin-left:$base-padding;
        max-width: 300px;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1.4rem;
        color: $base-color;
        font-weight: 600;

        &::before{
            content:"/";
            color:$base-color;
            margin-right:$base-padding;
            text-decoration: none;
        }

        &:first-child{
            margin-left:0;
            &::before{
                content:"";
                color:$base-color;
                margin-right:0;
                text-decoration: none;
            }
        }
    }

    a:hover{
        opacity:0.6;
    }

}