$base-padding:1rem;


.list-container-wrapper{
    margin-bottom:$base-padding*6;
    .button{
        margin: auto;
        display: block;
        margin-top: 2rem;
        word-break: break-all;
    }
    a{
        text-decoration: none;
    }
    .go-to-button{
        display: flex;
        font-size: 1.4rem;
        padding: 1rem;
        margin-top: 0;
        text-decoration: none;
        margin-top:0;
        svg{
            margin-left: 1rem;
            margin-right: 0;
        }
    }


    .button-text{
        word-break: break-all;
    }
}

.list-container{
    position: relative;
}

.list-overflow-wrapper{
    width: 100%;
    overflow-x: scroll;
    position: relative;
    margin-top: 0;
    .list-content{
        min-width: 950px;
        position: relative;
    }
}


.list-title{
    position: absolute;
    top: -26px;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 11;
    font-weight: 600;
    background: #FFF;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 100px;
    font-size: 1.2rem;
    text-align: center;
}

.list-header{
    /*position:sticky;
    top:57px;*/
    z-index: 10;
    background:#FFF;
    border-top: solid 1px darken(#f9f8f7,5%);
    border-bottom:solid 1px darken(#f9f8f7,5%);
    .list-item-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 0;
        font-weight: 600;
        font-size:1.2rem;
        max-width: 1280px;
        width:100%;
        margin:auto;
        padding:$base-padding*0.5 $base-padding;

        .thumbnail-item{
            width:5rem;
            margin:1rem;
        }

        .item{
            padding:$base-padding;

            &.width-fixed-250px{
                flex:0 0 250px;
            }

            &.width-fixed-175px{
                flex:0 0 175px;
            }

            &.width-fixed-150px{
                flex:0 0 150px;
            }

            &.width-fixed-100px{
                flex:0 0 100px;
            }

            &.width-fixed-120px{
                flex:0 0 120px;
            }

            &.width-auto{
                flex: auto 1 1;
            }
        }
    }
}

.list-body{
    border-bottom: solid 1px darken(#f9f8f7,5%);
    list-style: none;
    .list-item{
        background:#fcfcfb;
        transition: background-color 0.2s ease-out;
        &:nth-child(2n+1){
            background:#f9f8f7;
        }
    }

    .list-item .list-item-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 0;
        font-weight: 500;
        font-size:1.4rem;
        max-width: 1280px;
        width:100%;
        margin:auto;
        padding:$base-padding $base-padding;

        .thumbnail-item{
            width:5rem;
            margin:1rem;
        }

        .item{
            padding:$base-padding;

            &.width-fixed-250px{
                flex:0 0 250px;
            }

            &.width-fixed-175px{
                flex:0 0 175px;
            }

            &.width-fixed-150px{
                flex:0 0 150px;
            }

            &.width-fixed-100px{
                flex:0 0 100px;
            }

            &.width-fixed-120px{
                flex:0 0 120px;
            }

            &.width-auto{
                flex: auto 1 1;
            }
        }
    }


    .list-item .thumbnail-wrapper{
        height: 0;
        width: 7rem;
        position: relative;
    }

    .list-item .thumbnail{
        border-radius: 50%;
        overflow: hidden;
        width:4rem;
        box-shadow:0 1px 10px rgba(0,0,0,0.2);
        transition: transform 0.2s ease-out;

        transform: translateY(-50%);
        position: absolute;
        top: 50%;

        img{
            display: block;
            width:100%;
            height:auto;
        }
    }

    .list-item:hover{
        background:darken(#f9f8f7,3%);
        .thumbnail{
            transform:scale(1.1) translateY(-50%);
        }
    }
}