html{font-size: 62.5%;}
body { font-size: 16px; font-size: 1.6rem; } /* =14px */

@import url("https://use.typekit.net/ilo7ouf.css");

@import "./stylesheets/variables.scss";
@import "./stylesheets/mixins.scss";
@import "./stylesheets/body.scss";

@import "./stylesheets/buttons.scss";
@import "./stylesheets/breadCrumbs.scss";
@import "./stylesheets/tooltip.scss";
@import "./stylesheets/container-404.scss";

@import "./stylesheets/input.scss";
@import "./stylesheets/select-menu.scss";
@import "./stylesheets/table.scss";
@import "./stylesheets/checkbox.scss";

@import "./stylesheets/ad-preview.scss";
@import "./stylesheets/target-info.scss";

@import "./stylesheets/status.scss";

@import "./stylesheets/loading.scss";

::selection {
    background: $base-color; /* WebKit/Blink Browsers */
    color:#FFF;
}

::-moz-selection {
    background: $base-color; /* Gecko Browsers */
    color:#FFF;
}

*{
    padding:0;
    margin:0;
    box-sizing: border-box;
    color:$base-color;
    list-style: none;
    font-family: proxima-nova, sans-serif;
}

.list{
    margin-left:2rem;
    li{
        margin-bottom:2rem;
        margin-left:2rem;
        line-height:2rem;
        position: relative;
        &::before{
            content:"";
            position: absolute;
            top:$base-padding/2;
            left:-$base-padding*2;
            background:$base-color;
            width:.8rem;
            height:.8rem;
            border-radius:50%;

        }
    }
}

h1{
    font-weight: 600;
    font-size:2em;
}

h2{
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
}

h3{
    font-weight: 600;
    font-size:1.5em;
}

p{
    color:$secondary-color;
}


.ghost-element{
    visibility: hidden;
    position: relative;
    top:auto;
    left:auto;
}

.title-container{
    h2{
        font-size:2.5rem;
        margin-bottom:1rem;
    }
    p{
        font-size:1.4rem;
    }
}