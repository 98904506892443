
$base-padding:1rem;
$base-color:#24262b;
$secondary-color:#4b515b;
$font-color:#6c7481;


$main-text-color:#0d0d0d;

$main-color:rgb(255,106,19);
$secondary-color-1:rgb(0,178,178);
$secondary-color-2:rgb(0,109,123);

$secondary-color-1:#74B0FF;
$secondary-color-2:#5D87E8;
$secondary-color-1:#5D87E8;

$error-text: #721c24;
$error-bg: #f8d7da;
$error-border:#f5c6cb;

$success-text: #155724;
$success-bg: #d4edda;
$success-border: #c3e6cb;

/*$link-color:#0facf3;*/
$link-color:$secondary-color-1;

$link-color:#ff6a13;
$button-color:#ff6a13;
$button-text-color:#FFF;

$mobile-max-width:420px;