.loading{
    position: relative;
    pointer-events: none;

    color:rgba(0,0,0,0);
    transition: 0.2s ease-out color;


    &::before{
      content:'';
      z-index:10;
      position: absolute;
      display: block;
      width: 1.5em;
      height: 1.5em;
      margin-left: -.75em;
      margin-top: -.85em;
      border-radius: 50% 50%;
      border:3px solid rgba(0,0,0,0.1);
      border-bottom-color:#FFF;
      top:50%;
      left:50%;
      
      transform-origin: center;
      @include transform(rotate(0deg));
      animation: spin 0.4s linear infinite;
    }

    @keyframes spin {
      0%{
        @include transform(rotate(0deg));
      }

      100%{
        @include transform(rotate(360deg));
      }
    }
}