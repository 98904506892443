


.checkbox{
    display: flex;
    align-items:center;
    justify-content: flex-start;
    font-size:1.4rem;
    font-weight:600;
    cursor:pointer;
    margin:1rem;
    &::before{
        content:"";
        display: inline-block;
        width:20px;
        height:20px;
        line-height:20px;
        border-radius:3px;
        background:#fff;
        border:#e8ecf6 solid 1px;
        margin-right:0.5rem;
    }

    &.checked::before{
        content:"✔";
        color:#FFF;
        text-align: center;
        background:#ff6a13;
        border:#ff6a13 solid 1px;
    }

    input[type=checkbox]{
        height:0;
        width:0;
        visibility: hidden;
    }
}