.ad-preview-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    

    margin-top:$base-padding*4;
    margin-bottom:$base-padding*8;

    @media(min-width:720px){
        margin-top:$base-padding*6;
        margin-bottom:$base-padding*10;
        flex-direction: row-reverse;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    
}

.ad-preview{
    position: relative;
    max-width: 320px;
    width: 100%;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 3px;
    box-shadow: 0 0px 10px rgba(0,0,0,0.1), 0 3px 20px rgba(0,0,0,0.05);

    &::before{
        content:"Laddar förhandsvisning...";
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }

    iframe{
        position: relative;
        width: 100%;
        height:100%;
    }

}

.ad-image{
    max-width: 420px;
    width: 100%;
    display: block;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.1);
    border-radius:3px;
    transition: .5s ease-out transform, opacity .5s ease-out;
    opacity:0;
    transform:translateY(1rem);

    &.reveal-image{
        opacity:1;
        transform:translateY(0);
    }

    &:hover{
        transform:scale(1.02);
    }
}

.link-container{
    display:flex;
    flex-wrap:wrap;
    margin: -0.5rem;
    .text-link {
        margin:0.5rem;
    }

}