

.status{
    position: relative;
    display: block;
    text-transform: capitalize;


    &::before{
        content: "";
        display: block;
        width: 1rem;
        float: left;
        height: 1rem;
        border-radius: 50%;
        background: #DEDEE2;
        transform: translate(0, 50%);
        margin-right: 0.5rem;
        border: 1px solid rgb(203, 203, 206);
        margin-top: -4px;
    }

    &.ACTIVE::before{
        background:#41c15f;       
        border: 1px solid #379e4f;         
    }

    &.UNCOMPLETE::before{
        background:#FFE049;
        border: 1px solid rgb(237, 208, 64);   
    }

    &.DONE::before{
        background:#DEDEE2;
        border: 1px solid rgb(203, 203, 206);
    }

    &.PAUSED::before{
        background:#FFE049;
        border: 1px solid rgb(237, 208, 64);   
    }

    &.DELETED::before{

    }

    &.ARCHIVED::before{

    }
}