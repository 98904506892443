
.form{

    display:flex;
    flex-direction: column;
    width:100%;

    .input-wrapper{
        margin-bottom:$base-padding*2;
        position: relative;
        &.error{

            input{
                border-color:#e36370;
            }

            .error-message{
                max-height:4.2em;
            }
        }

        label{
            position: absolute;
            top:0;
            left:0;
            padding: 1.5rem 1rem;
            font-size: 0.9em;
            transform:translate(0,0);
            transform-origin: left;
            opacity:0.8;
            transition: .2s ease-out transform;
            pointer-events: none;
        }

        &.focus{
            label{
                transform:translate(-1rem,-4rem) scale(.9);
            }
        }

        input:focus + label{
            transform:translate(-1rem,-4rem) scale(.9);
        }

    }

    input[type=text], input[type=password]{
        width:100%;
        padding:$base-padding*1.5 $base-padding;
        border:none;
        border-radius:0.2em;
        font-size:0.9em;
        box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.05), 0 3px 10px -3px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
        border:solid 2px rgba(0,0,0,0);
        transition:border-color 0.2s ease-out;

        &:focus{
            outline:none;
            border-color:#0facf3;
        }

    }    

    .message{
        overflow:hidden;
        max-height:0;
        transition:0.3s ease-out max-height;

        p{
            padding:$base-padding;
            font-size:0.9em;
            margin-top:$base-padding;
        }


        &.info-message{
            p{
                color: $success-text;
                background-color: $success-bg;
                border: $success-border 1px solid;
            }
            &.success{
                max-height:4.2em;
            }

        }

        &.error-message{
            p{
                background:$error-bg;
                color:$error-text;
                border:1px solid #f5c6cb;
            }
        }
    }

    .button{
        box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.05), 0 3px 10px -3px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
    }


}
