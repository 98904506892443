.target_container{
    max-width: 320px;
    padding: 0;
    margin-top:$base-padding*4;

    @media(min-width:720px){
        margin-top:0;
        padding-right:$base-padding*2;
    }

    .title{
        font-weight: 600;
        font-size: 1.6rem;
        margin-bottom: 0.5rem;
        display: inline-block;
    }

    .target-info{
        margin-bottom: 2rem;
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 400;
    }
}